import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalInstance, apiRequest } from '../../authConfig'

const OAuthApiWrapper = {
	getToken: async (scopes:string[] = apiRequest.scopes): Promise<string> => {
		const accounts = msalInstance.getAllAccounts();
		const accessTokenRequest = {
			scopes: scopes,
			account: accounts[0],
		};

		return await msalInstance.acquireTokenSilent(accessTokenRequest)
			.then((accessTokenResponse) => {
				return accessTokenResponse.accessToken;
			})
			.catch((error) => {
				if (error instanceof InteractionRequiredAuthError) {
					msalInstance.acquireTokenPopup(accessTokenRequest);
				}
				console.log(error);
				return '';
			});
	}
};

export default OAuthApiWrapper;
