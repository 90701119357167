import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		margin: '3% auto'
	},
	logInButton: {
		fontFamily: "Libre Franklin",
		borderRadius: '0px',
		fontSize: '25px',
		cursor: 'pointer',
	}
}));

export default useStyles;
