import colors from 'utils/colors';
import { fabric } from 'fabric';
import { IFigure, IRectFigure } from '../canvasInterfaces';

export class Rectangle {
  figuresList   : IFigure[];
  canvasFabric  : fabric.Canvas;
  figureId      : number;
  floorId       : number;
  drawing       : boolean = false;
  newDrawedFig  : IFigure[] = [];
  rect          : fabric.Rect = new fabric.Rect();
  newRectFigure : IRectFigure = {
    figure:{
      id        : 0,
      currentId : 0,
      name      : '',
      description: '',
      floorId   : 0,
      figureId  : 0,
      height    : 0,
      width     : 0,
      radius    : 0,
      points    : "0",
      x         : 0,
      y         : 0,
      links     : [],
      save      : true,
      workstations: []
  }} ;
  

  constructor(
    figuresList : IFigure[], 
    canvasFabric:fabric.Canvas,
    floorId     : number,
    figureId    : number,
  ) {
    this.figuresList  = figuresList;
    this.canvasFabric = canvasFabric;    
    this.floorId      = floorId;    
    this.figureId     = figureId;    
  }

  // Getter
  get canvasDrawRectArray() {
    return this.drawRectFigures();
  }
  
  drawRectFigures() {
    this.figuresList.forEach((figure:any) => {
      // draw each figure inside canvas
      const opt = {
        id          : figure.id,
        currentId   : figure.id,
        height      : figure.height,
        width       : figure.width,      
        top         : figure.y,
        left        : figure.x,
        fill        : colors.lighterGrey,
        stroke      : colors.apexGrey3,
        strokeWidth : 2,
        figure      : figure,
        ...figure
      } as IRectFigure;
      const figureFabric = new fabric.Rect(opt);
      this.canvasFabric.add(figureFabric);
    });		
  }

  mouseDown(options:any){
    if (this.canvasFabric && this.canvasFabric.getActiveObject()) {
      const figure: IFigure = options.target?.figure;
      this.canvasFabric.getActiveObject()?.set('fill', colors.apexOrange2);
      this.setFiguresValues(figure, this.figuresList, false);
      return figure;
    } else {
      this.drawing = true;
      
      let newFigure: IFigure = {
        id        : 0,
        currentId : this.figuresList.length + 1,
        name      : '',
        description: '',
        floorId   : this.floorId,
        figureId  : this.figureId,
        height    : 0,
        width     : 0,
        radius    : 0,
        points    : "0",
        x         : options.pointer.x,
        y         : options.pointer.y,        
        links     : [],
        save      : true,
        workstations: []
      } 
      this.newRectFigure = {
        top         : options.pointer.y,
        left        : options.pointer.x,
        width       : 0,
        height      : 0,
        fill        : colors.lighterGrey,
        stroke      : colors.apexGrey3,
        strokeWidth : 2,
        figure      : newFigure
      } as IRectFigure;
      this.rect = new fabric.Rect(this.newRectFigure);
      this.canvasFabric.add(this.rect);
      this.setFiguresValues(newFigure, this.figuresList, true);
      return newFigure;
    }    
  }

  mouseMove(options:any){
    if (this.drawing) {
      this.rect.set({
        width : parseInt(Math.abs((this.newRectFigure.left ? this.newRectFigure.left : 0) - options.pointer.x).toFixed(0)),
        height: parseInt(Math.abs((this.newRectFigure.top ? this.newRectFigure.top : 0) - options.pointer.y).toFixed(0)),
        left  : parseInt(Math.min(options.pointer.x, (this.newRectFigure.left ? this.newRectFigure.left : 0)).toFixed(0)),
        top   : parseInt(Math.min(options.pointer.y, (this.newRectFigure.top ? this.newRectFigure.top : 0)).toFixed(0))
      });
      this.rect.setCoords();
      this.newRectFigure.figure.width   = parseInt(Math.abs((this.newRectFigure.left ? this.newRectFigure.left : 0) - options.pointer.x).toFixed(0));
      this.newRectFigure.figure.height  = parseInt(Math.abs((this.newRectFigure.top ? this.newRectFigure.top : 0) - options.pointer.y).toFixed(0));
      this.newRectFigure.figure.x       = parseInt(Math.min(options.pointer.x, (this.newRectFigure.left ? this.newRectFigure.left : 0)).toFixed(0));
      this.newRectFigure.figure.y       = parseInt(Math.min(options.pointer.y, (this.newRectFigure.top ? this.newRectFigure.top : 0)).toFixed(0));
      this.setFiguresValues(this.newRectFigure.figure, this.figuresList, true);
      this.canvasFabric.renderAll();
      return this.newRectFigure.figure;
    }
    return null;
  }

  mouseUp(options:any){
    if (this.canvasFabric.getActiveObject()) {
      if (options.target.figure) {
        const figure: IFigure = options.target.figure;
        figure.x      = Math.round(options.target.left);
        figure.y      = Math.round(options.target.top);
        figure.width  = Math.round(options.target.width * options.target.scaleX);
        figure.height = Math.round(options.target.height * options.target.scaleY);
        figure.save   = true;
        this.setFiguresValues(figure, this.figuresList, true);
        return figure;
      }
    } else {				
      this.drawing = false;
      this.rect.set({
        fill: colors.apexOrange2
      });
      this.canvasFabric.setActiveObject(this.rect);
      this.canvasFabric.renderAll();
      this.newDrawedFig = this.newDrawedFig.filter(item => item.currentId === this.newRectFigure.figure.currentId);
      this.newDrawedFig.push(this.newRectFigure.figure);
      
    }
    return null;
  }

  setFiguresValues = (
		figure: IFigure,
		figuresValues: IFigure[] | undefined,
		updateArray: boolean = false
	) => {		
		if (figuresValues && updateArray) {			
			let objIndex = figuresValues.findIndex((obj) => obj.currentId === figure.currentId);
			if (objIndex === -1){ objIndex = figuresValues.length;}
			//Update figure
			figuresValues[objIndex] = {...figure, save: true};
		}
	}
}