import axios from 'axios';
import { IBuildingT, IFloor, IZone } from 'types/shared';
import { apiURL, QueryParams } from 'utils/constants';

const buildingsURL = apiURL + 'Layout/Buildings';
const floorsURL = apiURL + 'Layout/Floors';
const zonesURL = apiURL + 'Layout/Zones';

const LayoutApiWrapper = {

	getZones: (params?: QueryParams): Promise<IZone[]> => {
		const zones = axios.get(zonesURL, { params }).then((result) => result.data as IZone[]);
		return zones;
	},

	getZone: (zoneId: number): Promise<IZone> => {
		const zone = axios.get(`${zonesURL}/${zoneId}`).then((result) => result.data as IZone);
		return zone;
	},

	getFloor: (floorId: number): Promise<IFloor> => {
		const result = axios
			.get(`${floorsURL}/${floorId}`)
			.then((result) => result.data as IFloor);

		return result;
	},

	getBuilding: (buildingId: number): Promise<IBuildingT> => {
		const result = axios
			.get(`${buildingsURL}/${buildingId}`)
			.then((result) => result.data as IBuildingT);

		return result;
	},
	
};

export default LayoutApiWrapper;
