import axios from 'axios';
import { IEmployee } from 'types/shared';
import { apiURL, QueryParams } from 'utils/constants';

const employeesURL = apiURL + 'projects';


const ProjectEmployeesApiWrapper = {
	
	getProjectEmployees: (projectId: number): Promise<IEmployee[]> => {
		const employee = axios
			.get(`${employeesURL}/${projectId}/employees`)
			.then((result) => result.data as IEmployee[]);
		return employee;
	},
};

export default ProjectEmployeesApiWrapper;
