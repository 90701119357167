import axios from 'axios';
import { IAzAdProfile } from 'types/shared';
import { graphConfig } from '../../authConfig';
import OAuthApiWrapper from './oAuthApiWrapper'

const formattedData = (data: any) => data

const MicrosoftGraphApiWrapper = {
    getPhoto: (): Promise<string> => {
        const photo = OAuthApiWrapper.getToken(graphConfig.scopes).then(async (accessToken) => {
            const image = await axios.get(`${graphConfig.graphMeEndpoint}photo/$value`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                responseType: 'blob'
            });
            const url_2 = window.URL || window.webkitURL;
            const blobUrl = url_2.createObjectURL(image.data as Blob);
            return blobUrl;
        });

        return photo;
    },
    getProfile: (): Promise<IAzAdProfile> => {
        const profile = OAuthApiWrapper.getToken(graphConfig.scopes).then(async (accessToken) => {
            const profileRequest = await axios.get(graphConfig.graphMeEndpoint, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
                responseType: 'json'
            });

            return formattedData(profileRequest.data);
        });

        return profile;
    }
};

export default MicrosoftGraphApiWrapper;
