import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	logOutButton: {
		background: 'none !important',
		width: '100%',
		padding: 0,
		fontSize: '1rem',
		textTransform: 'capitalize',
		color: '#44546A'
	}
}));

export default useStyles;
