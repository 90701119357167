import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination";
import Header from "./header";
import GroupsList from "./groupsList";
import { IGroup } from "types/shared";
import GroupsApiWrapper from "utils/apiWrappers/groupsApiWrapper";
import Loading from "components/loading/loading";
import { apiURL, QueryParams } from 'utils/constants';
import { off } from "process";

const GroupsContainer = () => {

  const [groupsFiltered, setGroupsFiltered] = useState<IGroup[]>([]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [loading, setloading] = useState(true);

  const [limit, setLimit] = useState(Number);
  const [offset, setOffset] = useState(Number);
  const [currentPage, setCurrentPage] = useState(Number);
  const [totalGroups, setTotalGroups] = useState(Number);
  const [lastIndex, setLastIndex] = useState(Number);
  const [activeButton, setActiveButton] = useState(Number);
  const quantity = 11;

  const fetchGroups = async (params?: QueryParams) => {

    if (totalGroups === 0) {
      const groupsCount = await GroupsApiWrapper.getGroups();
      setTotalGroups(groupsCount.length);
      setLastIndex(Number((groupsCount.length / quantity).toFixed()));
      setActiveButton(0);
      const groups = await GroupsApiWrapper.getGroups(params);
      setGroups(groups);
      setGroupsFiltered(groups);
    }
    if (params !== undefined && params.offset !== offset) {
      const groups = await GroupsApiWrapper.getGroups(params);
      setGroups(groups);
      setGroupsFiltered(groups);
    }
    setloading(false);
  };


  useEffect(() => {
    setloading(true);
    setLimit(quantity);
    setOffset(0);
    setCurrentPage(2);

    fetchGroups({
      limit: quantity,
      offset: 0
    });
  }, []);

  const search = (searchText: string, order: number) => {
    setTotalGroups(0);
    let orderFormated = "name, id ";
    if (order === 0)
    orderFormated += "asc";
    if (order === 1)
    orderFormated += "dec";
    
    let calculatedOffset = offset;
    if (calculatedOffset !== 0)
      calculatedOffset = 0;
    setOffset(calculatedOffset);
    fetchGroups({
      sort: orderFormated,
      value: searchText,
      offset: offset,
      limit: quantity
    });
  };

  //Handle pagination
  const prev = () => {
    if (offset === ((lastIndex - 1) * limit) && currentPage !== lastIndex) {
      current();
    }
    else {
      var calculatedCurrentPage = currentPage - 1;
      if (calculatedCurrentPage > 1)
        setCurrentPage(currentPage - 1);

      if (calculatedCurrentPage >= 0) {
        if (calculatedCurrentPage === 1) {
          first();
        }
        else {
          setActiveButton(1);
          calculatedCurrentPage = calculatedCurrentPage - 1;
          var calculatedOffset = calculatedCurrentPage * quantity;
          setOffset(calculatedOffset);

          fetchGroups({
            limit: limit,
            offset: calculatedOffset
          });
        }
      }
    }
  };

  const next = () => {
    var jumpNext = true;
    var calculatedCurrentPage = currentPage;
    if (activeButton === 0) {
      setActiveButton(1);
      jumpNext = false;
    }
    if (jumpNext)
      calculatedCurrentPage = currentPage + 1;
    if (calculatedCurrentPage < lastIndex) {
      setCurrentPage(calculatedCurrentPage);
      calculatedCurrentPage = calculatedCurrentPage - 1;
      if (calculatedCurrentPage - 1 >= 0) {
        var calculatedOffset = calculatedCurrentPage * quantity;
        setOffset(calculatedOffset);
        fetchGroups({
          limit: limit,
          offset: calculatedOffset
        });
      }
    }
    if (calculatedCurrentPage === lastIndex)
      last();
  };

  const current = () => {
    setActiveButton(1);
    var calculatedOffset = quantity;
    var calculatedCurrentPage = currentPage - 1;

    if (calculatedCurrentPage > 0)
      calculatedOffset = calculatedCurrentPage * quantity;
    setOffset(calculatedOffset);

    fetchGroups({
      limit: limit,
      offset: calculatedOffset
    });
  };

  const first = () => {
    setActiveButton(0);
    var calculatedOffset = 0;
    setOffset(calculatedOffset);

    fetchGroups({
      limit: limit,
      offset: calculatedOffset
    });
  };

  const last = () => {
    setActiveButton(2);
    const calculatedOffset = (lastIndex - 1) * limit;
    setOffset(calculatedOffset);

    fetchGroups({
      limit: totalGroups,
      offset: calculatedOffset
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <Header search={search} />
      <GroupsList groups={groupsFiltered} />
      <Pagination prev={prev} next={next} first={first} last={last} current={current} currentIndex={currentPage} lastIndex={lastIndex} activeButton={activeButton} />
    </>
  );
};

export default GroupsContainer;
