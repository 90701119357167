import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { apiRequest, graphConfig } from "../../../authConfig";
import Button from "@material-ui/core/Button";
import useStyles from "./SignInButton.styles";

interface props {
    handleAuthenticated: (email:any) => void;
  }

const SignInButton = ({handleAuthenticated}: props) => {
    const classes = useStyles();
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup( {scopes: [ ...apiRequest.scopes, ...graphConfig.scopes]}).then((loginResponse) =>{
            handleAuthenticated(loginResponse?.account?.username);
        }).catch(e => {
            console.error(e);
        });
    };

    useEffect(() => {
		handleLogin();
	}, []);

    return (
        <div className={classes.container}>
            <Button
                className={classes.logInButton}
                size="large"
                variant="contained"
                color="primary"
                name="columns"
                onClick={handleLogin}>
                PLEASE LOG IN
            </Button>
        </div>
    );
}

export default SignInButton;
