import React, { useEffect, useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { IEmployee, ISeat } from 'types/shared';
import useStyles from './styles';
import EmployeesApiWrapper from 'utils/apiWrappers/employeesApiWrapper';

interface seatInfoPopOverProps {
    employeeProject?: string;
	anchorEl: SVGRectElement | null;
	seat: ISeat | null;
	onClose: () => void;
}

const SeatInfoPopOver: React.FC<seatInfoPopOverProps> = ({
	anchorEl,
	seat,
	onClose,
	employeeProject,
}) => {
	const [employeeInfo, setEmployeeInfo] = useState<IEmployee | undefined>();
	useEffect(() => {
		if (seat?.employeeId) {
			setEmployeeInfo(undefined);
			const employeeData = async (employeID: number) => {
				EmployeesApiWrapper.getProjectEmployee(employeID).then((result) => {
					setEmployeeInfo(result[0]);
				});
			};
            employeeData(seat.employeeId);	
		}
	}, [seat]);
	const classes = useStyles();

	return (
		<Popover
			id="seat-popover"
			classes={{
				paper: classes.popOverOverride,
			}}
			elevation={0}
			style={{
				pointerEvents: 'none',
			}}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			onClose={onClose}
			disableRestoreFocus
		>
			{seat && (
				<div className={classes.seatInfoContainer}>
					<Typography className={classes.seatInfo}>
						{seat.description || `Seat ID: ${seat.id}`}
					</Typography>
					<Typography className={classes.seatInfo}>
						{employeeInfo?.fullName}
					</Typography>
					<Typography className={classes.seatInfo}>{employeeInfo?.projectName}</Typography>
				</div>
			)}
		</Popover>
	);
};

export default SeatInfoPopOver;
