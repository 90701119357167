import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './slider.styles.scss';


function Slider(props: any) {
  return (
    <Carousel> 
      <img
        src="https://www.apexsystems.com/sites/default/files/media-images/WhatWeDo_Hero.jpg"
        alt=""
      />     
      
      <img
        src="https://assets.easybroker.com/property_images/1680217/25425706/EB-FL0217.JPG?version=1596508769"
        alt=""
      />
      <img
        src="https://media-exp1.licdn.com/dms/image/C5622AQFKamapbR9JCg/feedshare-shrink_800/0/1658528766311?e=2147483647&v=beta&t=03BqrhQSHnO9YVT-YxniFPXhjX-NjgvJr2SFPashENo"
        alt=""
      />      
    </Carousel>
  );
}

export default Slider;
