import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({

        profileImage: {
                height: '58px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
        },
        profileInitials: {
                backgroundColor: '#37B3A2',
                width: '58px',
                height: '58px',
                borderRadius: '50%',
                color: '#44546A',
                fontSize: '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
        },
}));

export default useStyles;
