import axios from 'axios';
import { IProjectUserData } from 'types/shared';
import { apiURL } from 'utils/constants';

const projectsURL = apiURL + 'Projects';

const ProjectsApiWrapper = {
	getProjects: (): Promise<IProjectUserData[]> => {
		const floors = axios
			.get(projectsURL)
			.then((result) => {
					return result.data as IProjectUserData[]
				} 
			)
		return floors;
	},

};

export default ProjectsApiWrapper;
