import { Control, Controller } from 'react-hook-form';
import React from 'react';
import Select, { itemProps } from '../select';
import { IBuildingSelectLocation } from 'types/shared';

type FormSelectProps = {
	label: string;
	control: Control;
	name: string;
	items: itemProps[];
	defaultValue?: number;
	required?: boolean;
	handleChangeSelect: (resp: IBuildingSelectLocation) => void;
	disabled?: boolean;
	error?: boolean;
};

export var FormSelect = ({
	label,
	name,
	control,
	items,
	defaultValue,
	required,
	handleChangeSelect,
	disabled,
	error
}: FormSelectProps) => {

	const validateSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		e.currentTarget.setCustomValidity('');
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, value } }) => (
				<Select
					name={name}
					value={value || ''}
					onSelect={(e) => {
						(e?.target as HTMLInputElement).value && required ? (e.target as HTMLSelectElement).setCustomValidity('') : (e.target as HTMLSelectElement).setCustomValidity(`Select a ${label}`)
						}
					}
					onChange= {
						(e) => {			
							onChange(e);
							handleChangeSelect({ value: Number(e?.target.value), name: name });
						}
					}
					label={label}
					items={items}
					defaultValue={defaultValue}
					required={required}
					disabled={disabled}
					error={error && !value}
				/>
			)}
		/>
	);
};