import React, { Dispatch, SetStateAction } from 'react';
import GenericModal from 'components/modals/generic/modal';
import { Box, Button } from '@material-ui/core';
import useStyles from '../../../pages/homePage/components/sidebar/sideBar.styles';
import ZonesApiWrapper from 'utils/apiWrappers/zonesApiWrapper';
import { IZone } from '../../../types/shared/index';
import { selectTab } from 'redux/home/home.action';
import { useDispatch } from 'react-redux';

interface SaveZonesModalProps {
	modalIsOpen: boolean;
	setModalIsOpen: Dispatch<SetStateAction<boolean>>;
	isError: boolean;
	setIsError: (isError: boolean) => void;
	zones: IZone[] | undefined;
}

function SaveZonesModal({
	modalIsOpen,
	setModalIsOpen,
	isError,
	setIsError,
	zones
}: SaveZonesModalProps) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onSaveClick = (shouldContinue: boolean = false) => {
		if (zones) {
			saveZonesUpdates(0, zones).then((zonesResult) => {				
				if (zonesResult) {
					finishProcess(shouldContinue);
				} else {
					setIsError(true);
				}
			});
		}
	}

	const saveZonesUpdates = async (index: number, zones: IZone[], success: boolean = true): Promise<boolean> => {		
		if (zones.length > 0) {			
			const zone:any = { ...zones[index]};
			console.log({zoneBefore:zone})
			// if the zone to create have save status and have value set on [height, width]
			if ( zone.height > 0 && zone.width > 0 && zone.save){				
				const { save, currentId, ...restZone } = zone;
				zones[index] = restZone;
				// if the zone id is 0 then is a new zone
				if (zone.id === 0) {
					await ZonesApiWrapper.postZone(zone)
					.then(({data}) => {
						// set the new id zone 						
						zones[index].id = data.id;
					})
					.catch(() => { success = false; })
					.finally(()=> {
						index++;
						if (index < zones.length) { saveZonesUpdates(index, zones, success);} 
						return success;			
					});
				} else {
					// if the zone id > 0 then it's an update
					await ZonesApiWrapper.putZone(zone)
						.then()
						.catch(() => { success = false; })
						.finally(()=> {
							index++;
							if (index < zones.length) { saveZonesUpdates(index, zones, success);} 
							return success;
						});
				}
			} else {
				index++;
				if (index < zones.length) { saveZonesUpdates(index, zones, success);} 
				return success;
			}
		}
		return success;
	}

	const finishProcess = (shouldContinue: boolean = false) => {
		setModalIsOpen(false);
		if (shouldContinue) {
			console.log('continue');
			dispatch(selectTab(3));
		}
	}

	return (
		<GenericModal
			title="Save Zones"
			isOpen={modalIsOpen}
			onClose={() => setModalIsOpen(false)}
			content={<p>Are you sure you want to save changes?</p>}
			isError={isError}
			errorMessage="Error on save"
			footer={
				<Box className={classes.modalFooter}>
					<Button
						variant="outlined"
						color="secondary"
						className={classes.modalButton}
						onClick={() => setModalIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						disabled={isError}
						variant="contained"
						color="secondary"
						className={classes.modalButton}
						onClick={() => onSaveClick()}
					>
						Save
					</Button>
					<Button
						disabled={isError}
						variant="contained"
						color="secondary"
						className={classes.modalButton}
						onClick={() => onSaveClick(true)}
					>
						Save & Continue
					</Button>
				</Box>
			}
		/>
	);
}

export default SaveZonesModal;