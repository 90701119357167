import axios from 'axios';
import { IEmployee, IEmployeeData, IEmployeeDataResp, IUser } from 'types/shared';
import { apiURL, QueryParams } from 'utils/constants';

const employeesURL = apiURL + 'Employees';

type EmployeesParams = QueryParams & {
	fullname?: string;
	projectName?: string;
	limit?: number;
	offset?: number;
	sort?: string;
};

const EmployeesApiWrapper = {
	getEmployees: (params?: EmployeesParams): Promise<IEmployee[]> => {
		const employees = axios
			.get(`${employeesURL}?limit=500`, { params })
			.then((result) => result.data as IEmployee[]);
		return employees;
	},

	getProjectEmployee: (employeeId: number): Promise<IEmployee[]> => {
		const employee = axios
			.get(`${employeesURL}/${employeeId}/projects`)
			.then((result) => result.data as IEmployee[]);
		return employee;
	},


	getEmployee: (employeeId: number): Promise<IEmployee> => {
		const employee = axios
			.get(`${employeesURL}/${employeeId}`)
			.then((result) => result.data as IEmployee);
		return employee;
	},
	getEmployeeAuthData: (email: string): Promise<IEmployeeData | any> => {
		const employee = axios
		.get(`${employeesURL}/${email}/projects`)
		.then((result) => result.data as IEmployeeData)
		return employee;
	},
	postEmployee: (employee: IUser | undefined): Promise<IEmployeeDataResp> => {
		const employeeData = {
				email: employee?.email,
				name: employee?.name,
				lastName: employee?.surname,
				guid: employee?.obId
			}
		return axios.post(employeesURL, employeeData).then((result) => result.data as IEmployeeDataResp);
	},
	postEmployeeProjects: (employeeId: number, projects: Array<number | undefined> | undefined): Promise<IEmployeeData> => {
		return axios.post(`${employeesURL}/${employeeId}/projects`, projects).then((result) => result.data as IEmployeeData);
	},
	putEmployee: (employee: IUser): Promise<IEmployeeDataResp> => {
		const employeeData = {
				id: employee?.id,
				email: employee?.email,
				name: employee?.name,
				lastName: employee?.surname,
				guid: employee?.obId
			}
		return axios.put(employeesURL, employeeData).then((result) => result.data as IEmployeeDataResp);
	},
	putEmployeeProjects: (employeeId: number, projects: Array<number | undefined> | undefined): Promise<IEmployeeData> => {
		return axios.put(`${employeesURL}/${employeeId}/projects`, projects).then((result) => result.data as IEmployeeData);
	},
};

export default EmployeesApiWrapper;
