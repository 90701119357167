import React, { Dispatch, SetStateAction } from 'react';
import GenericModal from 'components/modals/generic/modal';
import { Box, Button } from '@material-ui/core';
import useStyles from '../../../pages/homePage/components/sidebar/sideBar.styles';
import ZonesApiWrapper from 'utils/apiWrappers/zonesApiWrapper';
import { selectTab } from 'redux/home/home.action';
import { useDispatch } from 'react-redux';

interface DeleteZonesModalProps {
	modalIsOpen: boolean;
	setModalIsOpen: Dispatch<SetStateAction<boolean>>;
	isError: boolean;
	setIsError: (isError: boolean) => void;
	zoneId: number | undefined;
	zoneName: string | undefined;
	canvas: any;
	resetInputValues: Function;
}

function DeleteZonesModal({
	modalIsOpen,
	setModalIsOpen,
	isError,
	setIsError,
	zoneId,
	zoneName,
	canvas,
	resetInputValues
}: DeleteZonesModalProps) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onDeleteClick = async (shouldContinue: boolean = false) => {
		if (zoneId) {
			await ZonesApiWrapper.deleteZone(zoneId)
				.then(() => {
					canvas.remove(canvas.getActiveObject());
					resetInputValues(zoneId);
					setModalIsOpen(false);
					if (shouldContinue) {
						dispatch(selectTab(3));
					}
				})
				.catch(() => setIsError(true));
		} else {
			canvas.remove(canvas.getActiveObject());
			resetInputValues();
			setModalIsOpen(false);
			if (shouldContinue) {
				dispatch(selectTab(3));
			}
		}
	}

	return (
		<GenericModal
			title="Delete Zone"
			isOpen={modalIsOpen}
			onClose={() => setModalIsOpen(false)}
			content={<p>Are you sure you want to delete the zone <strong>{zoneName}</strong>?</p>}
			isError={isError}
			errorMessage="Error on Delete"
			footer={
				<Box className={classes.modalFooter}>
					<Button
						variant="outlined"
						color="secondary"
						className={classes.modalButton}
						onClick={() => setModalIsOpen(false)}
					>
						Cancel
					</Button>
					<Button
						disabled={isError}
						variant="contained"
						color="secondary"
						className={classes.modalButton}
						onClick={() => onDeleteClick()}
					>
						Delete
					</Button>
					<Button
						disabled={isError}
						variant="contained"
						color="secondary"
						className={classes.modalButton}
						onClick={() => onDeleteClick(true)}
					>
						Delete & Continue
					</Button>
				</Box>
			}
		/>
	);
}

export default DeleteZonesModal;