import React, { useState,  useEffect } from 'react';
import { Box, TextField } from '@material-ui/core';
import { fabric } from 'fabric';
import useStyles from './styles';
import DropDownList from '../dropdown-list/dropdown-list';
import InputNumber from '../inputNumber/index';
import { IBuilding, IFloor, IZone } from '../../types/shared/index';
import ZonesApiWrapper from 'utils/apiWrappers/zonesApiWrapper';
import DrawMenuContainer from '../../containers/dev/draw-menu';
import SaveZonesModal from './modals/SaveZonesModal';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import DeleteZonesModal from './modals/DeleteZonesModal';
import { Rectangle } from 'components/draw/figures/drawRectangle';
import { IFigure } from 'components/draw/canvasInterfaces';

interface ZoneEditorProps {
	activeBuilding: IBuilding | undefined;
	activeFloor: IFloor | undefined;
}

const ZoneEditor: React.FC<ZoneEditorProps> = ({ activeBuilding, activeFloor }) => {
	
	const [areaType, setAreaType] = useState('Rectangle');
	const handleAreaTypeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		setAreaType(event.target.value as string);
	};
	const areaTypes = ['Rectangle', 'Circle', 'Polygon'];
	const [zoneId, setZoneId] = useState(0);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [positionX, setPositionX] = useState(0);
	const [positionY, setPositionY] = useState(0);
	const [description, setDescription] = useState('');
	const [name, setName] = useState('');
	const [canvas, setCanvas] = useState<any>();
	const [zones, setZones] = useState<IZone[]>();
	
	// save modal
	const [isError, setIsError] = useState(false);
	const [modalSaveIsOpen, setModalSaveIsOpen] = useState(false);
	// delete modal	
	const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

	// page dimensions
	const { pageHeight, pageWidth } = useWindowDimensions();
	const [ canvasHeight, setCanvasHeight ] = useState<number>(0);
	const [ canvasWidth, setCanvasWidth ] = useState<number>(0);	

	const classes = useStyles({ height: canvasHeight });
	
	// get zonez data of current floor
	useEffect(() => {
		setCanvasWidth(pageWidth - getPercentage(pageWidth, 32))
		setCanvasHeight(pageHeight - 380)
		
		if (activeBuilding && activeFloor) {
			ZonesApiWrapper.getFloorZones(activeBuilding.id, activeFloor.id).then((response: IZone[]) => {
				startCanvasFabric(response as IZone[]);
			});
		}
	}, [activeBuilding, activeFloor]);

	const startCanvasFabric = (zonesResp: IZone[]) => {
		const canvasFabric = new fabric.Canvas('canvas', {
			fireRightClick: true,
			fireMiddleClick: true,
			stopContextMenu: true,
			selection: false
		});
		
		const rectangle = new Rectangle(
			zonesResp as IFigure[],
			canvasFabric,
			activeFloor?.id || 0,
			1
		);
		setZones(zonesResp);
		// draw all provided figures type rect
		rectangle.drawRectFigures();

		// set orange color of figure and selected values on input
		canvasFabric.on('mouse:down', (options: any) => {
			canvasFabric.getObjects().map((obj) => {
				if (obj) obj.set('fill', '#EFF0F2');
			});
			const result = rectangle.mouseDown(options);
			if (result) {
				setZoneValues(result as IZone,zones, true);
			}
		});

		// Handle dragging when drawing

		canvasFabric.on('mouse:move', (options: any) => {
			const result = rectangle.mouseMove(options);
			if (result) {
				setZoneValues(result as IZone,zones, true);
			}
		});

		// update edited values on zones array
		canvasFabric.on('mouse:up', (options: any) => {
			const result = rectangle.mouseUp(options);
			if (result) {
				setZoneValues(result as IZone,zones, true);
			}
		});
		
		setCanvas(canvasFabric);
	};

	const getPercentage = (val: number, per:number) => (per * val) / 100
	
	const resetInputValues = (zoneId: number) => {
		if (zones && zones.length > 0) {
			const deletedZone = zones.find(zone => zone.id === zoneId);
			if (deletedZone)
				zones.splice(zones.indexOf(deletedZone), 1);
		}
		setZoneId(0);
		setName('');
		setDescription('');
		setPositionX(0);
		setPositionY(0);
		setWidth(0);
		setHeight(0);
	}

	const setZoneValues = (
		zone: IZone,
		zonesValues: IZone[] | undefined,
		updateArray: boolean = false
	) => {
		setZoneId(zone.id);
		setName(zone.name|| '');
		setDescription(zone.description || '');
		setPositionX(zone.x);
		setPositionY(zone.y);
		setWidth(zone.width);
		setHeight(zone.height);
		// if (zonesValues && updateArray) {
		// 	//Find index of zone id
		// 	const objIndex = zonesValues.findIndex((obj) => obj.id === zone.id);
		// 	//Update zone
		// 	zonesValues[objIndex] = zone;
		// 	setZones(zonesValues);
		// }
		const rectangle = new Rectangle(
			zonesValues as IFigure[],
			canvas,
			activeFloor?.id || 0,
			1				
		);
		
		if (zonesValues){
			rectangle.setFiguresValues(
				zone as IFigure,
				zonesValues as IFigure[],
				updateArray
			)
		}
	};

	const zoneInputUpdate = (value: number | any, type: string) => {
		switch (type) {
			case 'height':
				setHeight(value);
				break;
			case 'width':
				setWidth(value);
				break;
			case 'left':
				setPositionX(value);
				break;
			case 'top':
				setPositionY(value);
				break;
			case 'name':
				setName(value.target.value);
				break;
			case 'description':
				setDescription(value.target.value);
				break;
		}

		if (canvas.getActiveObject()) {
			// update zone
			const canvasSelectedFigure 				= canvas.getActiveObject();
			canvasSelectedFigure.figure.save 	= true;
			const zone: IZone 								= canvasSelectedFigure.figure;
			let updateCanvas 									= true;
			let scale 												= true;

			switch (type) {
				case 'height':
					zone.height = value;
					break;
				case 'width':
					zone.width = value;
					break;
				case 'left':
					zone.x = value;
					scale = false;
					break;
				case 'top':
					zone.y = value;
					scale = false;
					break;
				case 'name':
					zone.name = value.target.value;
					updateCanvas = false;
					break;
				case 'description':
					zone.description = value.target.value;
					updateCanvas = false;
					break;
			}

			setZoneValues(zone, zones, true);

			// update canvas
			if (updateCanvas) {
				if (scale) {
					const scale = canvasSelectedFigure.getObjectScaling();
					canvasSelectedFigure.set(type, value / scale.scaleX);
				} else {
					canvasSelectedFigure.set(type, value);
				}
				canvasSelectedFigure.setCoords();
				canvas.requestRenderAll();
			}
		}
	};

	const onFiguresClick =()=> {
		console.log('Zone figures click');
	}
	
	const onSaveClick = () => {		
		setModalSaveIsOpen(true);
	}

	const onEditClick =()=> {
		console.log('Zone edit click');
	}

	const onDeleteClick =()=> {
		console.log('Zone delete click', name, zoneId);
		setModalDeleteIsOpen(true);
	}

	return (
		<div>
			<Box className={classes.optionsBox}>
				<TextField
					label="Name"
					InputLabelProps={{ shrink: true }}
					placeholder="Name"
					value={name}
					onChange={(value: any) => zoneInputUpdate(value, 'name')}
				/>

				<DropDownList
					name={'areaType'}
					items={areaTypes}
					inputLabel={'Area Type'}
					handleSelect={handleAreaTypeSelect}
					itemSelected={areaType}
				></DropDownList>

				<InputNumber
					name="Height"
					label="Height"
					placeholder="0"
					value={height}
					handleChange={(value: number) => zoneInputUpdate(value, 'height')}
				/>

				<InputNumber
					name="Width"
					label="Width"
					placeholder="0"
					value={width}
					handleChange={(value: number) => zoneInputUpdate(value, 'width')}
				/>

				<InputNumber
					name="Position X"
					label="Position X"
					placeholder="0"
					value={positionX}
					handleChange={(value: number) => zoneInputUpdate(value, 'left')}
				/>

				<InputNumber
					name="Position Y"
					label="Position Y"
					placeholder="0"
					value={positionY}
					handleChange={(value: number) => zoneInputUpdate(value, 'top')}
				/>

				<TextField
					label="Description"
					placeholder="Add a description"
					multiline
					rows={2}
					InputLabelProps={{ shrink: true }}
					value={description}
					onChange={(value: any) => zoneInputUpdate(value, 'description')}
				/>
			</Box>
			<div className={classes.canvasContainer}>
				
				<div className="content">
					<div className="xAxisLabels">						
						<span>&nbsp;</span>
						<span>X</span>
						<span>{canvasWidth}</span>
					</div>
					<div className='dottedSpacedTop'>&nbsp;</div>
					<div className="dashedAxis">
						<div className="yAxisLabels">
							<span>0</span>
							<span>Y</span>
							<span>{canvasHeight}</span>
						</div>
						<div className='dottedSpacedLeft'></div>
						<canvas id="canvas" width={canvasWidth} height={canvasHeight}></canvas>
					</div>
					
					<div className="spaces">
						<span className="square orange"></span>Selected
						<span className="square"></span>Available						
					</div>
				</div>
			</div>

			<DrawMenuContainer
				onFiguresClick={onFiguresClick}
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
				onSaveClick={onSaveClick}
			/>
			
			<SaveZonesModal					
				modalIsOpen={modalSaveIsOpen}
				setModalIsOpen={setModalSaveIsOpen}				
				isError={isError}
				setIsError={setIsError}
				zones={zones}
			/>

			<DeleteZonesModal 
				modalIsOpen={modalDeleteIsOpen}
				setModalIsOpen={setModalDeleteIsOpen}				
				isError={isError}
				setIsError={setIsError}
				zoneId={zoneId}
				zoneName={name}
				canvas={canvas}
				resetInputValues={resetInputValues}
			/>
		</div>
	);
};

export default ZoneEditor;