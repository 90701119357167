import React from 'react';
import {
	FormControl,
	InputLabel,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	MenuItem,
	makeStyles,
	FormHelperText
} from '@material-ui/core';
import { ReactComponent as selectIcon } from 'assets/icons/select-icon.svg';
import colors from 'utils/colors';

const useStyles = makeStyles({
	icon: {
		height: '9px',
		margin: 'auto',
		padding: '6px'
	},
	root: {
		textAlign: 'left',
		fontSize: '16px',
		lineHeight: '24px',
		color: colors.apexBlue,
		font: 'Libre Franklin',
		letterSpacing: '0px',
		opacity: '1',
		fontWeight: 'bold'
	}
});

export type itemProps = {
	name: string;
	id: string | number;
};
export type SelectProps = MuiSelectProps & {
	items: itemProps[];
	label?: string;
	required?: boolean;
	name: string;
	value?: any;
};

const Select = (props: SelectProps) => {
	var { label, labelId, items, id, required, name, value, error } = props;
	const classes = useStyles();
	var errorVal = (value ? false : true) && error && required;
	return (
		<FormControl fullWidth required={required} error={errorVal}>
			<InputLabel shrink={true} htmlFor={id} id={`lmsBuildingSelect${labelId}`}>
				{label}
			</InputLabel>
			<MuiSelect
				id={`lmsBuildingSelect${labelId}`}
				required={required}
				error={errorVal}

				inputProps={{
					classes: {
						icon: classes.icon,
						root: classes.root
					}
				}}
				IconComponent={selectIcon}
				color="secondary"
				{...props}
			>
				{items.map((item) => (
					<MenuItem key={Symbol(item.id).toString()} value={item.id}>
						{item.name}
					</MenuItem>
				))}
			</MuiSelect>
			{errorVal ? <FormHelperText>{`Field ${label} is required`}</FormHelperText> : ``}
		</FormControl>
	);
};

export default Select;
