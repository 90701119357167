import axios from 'axios';
import { areaByFigureValues } from 'pages/floorScreen/form/floorFields';
import { IAreaByFigure } from 'types/shared';
import { apiURL } from 'utils/constants';

const areaByFigureURL = apiURL + 'AreabyFigures';

const AreaByFiguresApiWrapper = {
	
	postAreaByFigure: (areaByFigure: areaByFigureValues): Promise<IAreaByFigure> => {
		return axios.post(areaByFigureURL, areaByFigure).then((result) => result.data as IAreaByFigure);
	},

};

export default AreaByFiguresApiWrapper;
