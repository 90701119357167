import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import SearchComponent from "../search/search";
import LmsIcon from "assets/icons/lms.svg";
import "./header.styles.scss";
import { IEmployee } from "types/shared";
import EmployeesApiWrapper from "utils/apiWrappers/employeesApiWrapper";
import { AuthenticatedTemplate } from "@azure/msal-react";
import ImageComponent from "./profile/image.component";
import { useDispatch } from 'react-redux';
import { showTabs, selectTab } from 'redux/home/home.action';
import './header.styles.scss';
import GearComponent from './profile/gear.component';
import { useNavigate } from 'react-router-dom';
import { selectBuilding } from 'redux/buildings/actions';
import { selectFloor } from 'redux/floors/actions';

const Header = () => {
	const [searchResults, setSearchResults] = useState<IEmployee[] | null>(null);
	const [errorOnSearch, setErrorOnSearch] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const beginSearch = async (filterSearch: string, searchText: string) => {
		setErrorOnSearch(false);
		const params =
			filterSearch === "Name"
				? { fullname: searchText }
				: filterSearch === "Project"
					? { projectName: searchText }
					: {};

		try {
			const employees = await EmployeesApiWrapper.getEmployees(params);

			if (employees) return setSearchResults(employees);
		} catch (error) {
			console.log(error);
			setErrorOnSearch(true);
		}
		setSearchResults(null);
	};

	const goHome = () => {
		dispatch(selectBuilding(undefined));
		dispatch(selectFloor(undefined));
		navigate("/");
	}

	return (
		<div className="headerRoot">
			<AppBar className="appBar">
				<Toolbar className="toolBarContainer">
					<div className="logoWrapper">
						<div className="logoWrapperLink" id="lmsHeaderLogo" onClick={goHome} >
							<img src={LmsIcon} className="lmsIcon" alt='logo' />
							<div className="lmsText">Layout Management System</div>
						</div>
					</div>
					<AuthenticatedTemplate>
						<SearchComponent
							beginSearch={beginSearch}
							searchResults={searchResults}
							clearResults={() => setSearchResults(null)}
							error={errorOnSearch}
						/>
						<div className="profileWrapper">
							<GearComponent />
							<div className="profileButton">
								<ImageComponent />
							</div>
						</div>
					</AuthenticatedTemplate>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default Header;
