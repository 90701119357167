import React from "react";
import { Button, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./styles";
import { paginationModel } from "./paginationModel";
import { current } from "@reduxjs/toolkit";
import { isatty } from "tty";

const Pagination = (props: paginationModel) => {
  const { prev, next, first, last, current, currentIndex, lastIndex, activeButton } = props;
  const { button, buttonEnd, pagination, arrow } = styles();

  var firstButton = `${button} ${buttonEnd}`;
  var firstButtonDisabled = false;

  var currentButton = `${button} ${buttonEnd}`;

  var lastButton = `${button} ${buttonEnd}`;
  var lastButtonDisabled = true;

  switch (activeButton) {
    case 0:
      firstButtonDisabled = true;
      lastButtonDisabled = false;     
      firstButton = button;
      break;
    case 1:
      firstButtonDisabled = false;
      lastButtonDisabled = false;
      currentButton = button;
      break;
    case 2:
      firstButtonDisabled = false;
      lastButtonDisabled = true;
      lastButton = button;
      break;
  }
  
  return (
    <p className={pagination}>
      <IconButton className={arrow} onClick={prev} color="primary" disabled={firstButtonDisabled}>
        <ArrowBackIosIcon />
      </IconButton>
      <Button
        onClick={first}
        className={firstButton}
        type="submit"
        color="secondary"
      >
        1
      </Button>
      <label className={arrow} color="primary">...</label>
      <Button
        onClick={current}
        className={currentButton}
        type="submit"
        color="secondary"
      >
        {currentIndex}
      </Button>
      <label className={arrow} color="primary">...</label>
      <Button
        onClick={last}
        className={lastButton}
        type="submit"
        color="secondary"
      >
        {lastIndex}
      </Button>
      <IconButton className={arrow} onClick={next} color="primary" disabled={lastButtonDisabled}>
        <ArrowForwardIosIcon />
      </IconButton>
    </p>
  );
};

export default Pagination;
