import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		margin:'60px auto',
		width:'50%',
		padding:'1%'
	},
	cardActions: {
		justifyContent: 'center'
	},
	subtitle: {
		marginTop:'5%',
		marginBottom:'2%',
		align:'center'
	},
	subtitleIcon: {
		background:'#44546A',
		marginRight:'10px'
	},
	input: {
		color: '#000',
		fontWeight: 'bold',
		fontsize: '16px',
		textTransform: 'uppercase'
	},
	typography: {
		color: '#333',
	},
	typographyGreen: {
		color: '#37B3A2'
	},
	avatar:{
		marginTop: '-65px',
		position: 'absolute',
		width: '58px',
		height: '58px',
	},
	marginTop:{
		marginTop: '5%'
	},
	avatarSmall:{
		width: 35, 
		height: 35,
		background: '#e0e0e0'
	},
	button:{
		width: '100px',
		height: '50px',
		fontWeight: 'bold'
	},
	circularProgress:{
		color:"#44546A",
		position: 'absolute',
		marginLeft: '0px !important'
	},
	checkCircleOutlined:{
		width: '30%',
		height: '30%'
	},
	dialogTitle:{
		background: '#37B3A2',
		textAlign: 'center',
		color: '#fff'
	},
	dialogContentText:{
		color: '#000',
		textAlign: 'center',
		fontSize: '20px',
		margin: '0',
		padding: '5%',
		fontWeight: 'bold'
	},
}));

export default useStyles;
