import React, { useEffect, useState } from "react";
import useStyles from "./profile.styles";
import MicrosoftGraphApiWrapper from 'utils/apiWrappers/microsoftGraphApiWrapper';
import { useMsal } from "@azure/msal-react";

const ImageComponent: React.FC = () => {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState('')
    const [initials, setInitials] = useState('')
    const { accounts } = useMsal();

    const getInitials = () => {
        const fullName = accounts[0] && accounts[0].name as string;
        const allNames = fullName.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
        return initials;
    }

    useEffect(() => {
        MicrosoftGraphApiWrapper.getPhoto()
            .then((photo) => {
                setImageUrl(photo);
            })
            .catch(function (e) {
                console.log(e);
                setInitials(getInitials());
            });
    }, [])

    return (
        <div>
            {imageUrl && <img className={classes.profileImage} src={imageUrl} />}
            {initials && <span className={classes.profileInitials}>{initials}</span>}
        </div>
    );
};

export default ImageComponent;
