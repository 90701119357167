import * as msal from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: "3e26d60d-2fc0-467d-b583-de6225a78ed3",
    authority: "https://login.microsoftonline.com/d867a851-bf46-47f7-8cd4-b768453c6b78",
    redirectUri: "https://layout-management.netlify.app",
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const apiRequest = {
  scopes: [
    "api://3e26d60d-2fc0-467d-b583-de6225a78ed3/LayoutManagement.Access"
  ]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/",
  scopes: [
    "user.read"
  ]
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);