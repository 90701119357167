import { useEffect, useState } from "react";
import { Avatar, Breadcrumbs, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { AccountCircle, Add, ClearSharp, LibraryBooks, NavigateNext, PinDrop, AssignmentInd, CheckCircleOutlined } from "@material-ui/icons";
import useStyles from './styles';
import { IUser, IProject, IAzAdProfile, IEmployeeDataResp, IEmployeeData, IProjectUserData } from "types/shared";
import ImageComponent from "components/header/profile/image.component";
import Loading from "components/loading/loading";
import MicrosoftGraphApiWrapper from "utils/apiWrappers/microsoftGraphApiWrapper";
import EmployeesApiWrapper from 'utils/apiWrappers/employeesApiWrapper';
import ProjectsApiWrapper from "utils/apiWrappers/projectsApiWrapper";
import { useNavigate, useLocation } from "react-router";
import { CircularProgress } from "react-cssfx-loading";

interface props {
  propUserData: any;
  handleClickSave: () => void;
}

const ProfilePage = ({propUserData, handleClickSave}: (props)) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [azAdProfile, setAzAdProfile] = useState<IAzAdProfile | undefined>();
  const [userData, setUserData] = useState(state?.propUserData ? state?.propUserData : propUserData);
  const [user, setUser] = useState<IUser | undefined>();
  const [project, setProject] = useState<number | undefined>(undefined);
  const [projects, setProjects] = useState<Array<IProjectUserData>>()
  const [loading, setloading] = useState(true);
  const [loadingButton, setloadingButton] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    navigate("/");
  };
  const handleSubmit = (event: any) => {
    setloadingButton(true);
    if (userData && userData.id) {
      const userUpdate = { ...user, id: userData.id } as IUser;
      EmployeesApiWrapper.putEmployee(userUpdate)
        .then((resp: IEmployeeDataResp) => {
          updateEmployeeProjects(userData?.id, user?.projects)
        }
        )
        .catch((error: any) => console.log(error))
    } else {
      EmployeesApiWrapper.postEmployee(user)
        .then((resp: IEmployeeDataResp) => {
          if (user?.projects && user?.projects?.length > 0) {
            saveEmployeeProjects(resp?.id, user?.projects)
          }
          else {
            setOpen(true);
            setloadingButton(false);
            handleClickSave();
          }
        }
        )
        .catch((error: any) => console.log(error))
    }
    event.preventDefault();
  };
  const saveEmployeeProjects = (employeeId: number, projects: Array<IProject> | undefined) => {
    const projectsId = projects?.map(proj => proj?.id);
    EmployeesApiWrapper.postEmployeeProjects(employeeId, projectsId)
      .then((resp: IEmployeeData) => {
        setOpen(true);
        setloadingButton(false);
        handleClickSave();
      })
      .catch((error: any) => {
        console.log(error)
      })
  };
  const updateEmployeeProjects = (employeeId: number, projects: Array<IProject> | undefined) => {
    const projectsId = projects?.map(proj => proj?.id);
    EmployeesApiWrapper.putEmployeeProjects(employeeId, projectsId)
      .then((resp: IEmployeeData) => {
        setOpen(true);
        setloadingButton(false);
        handleClickSave();
      })
      .catch((error: any) => {
        console.log(error);
      })
  };
  const handleChangeName = (event: any) => {
    const userData = { ...user } as IUser;
    userData.name = event.target.value;
    setUser(userData);
  };
  const handleChangeSurname = (event: any) => {
    const userData = { ...user } as IUser;
    userData.surname = event.target.value;
    setUser(userData);
  };
  const handleChangeEmail = (event: any) => {
    const userData = { ...user } as IUser;
    userData.email = event.target.value;
    setUser(userData);
  };
  const handleChangeProjectName = (event: any) => {
    setProject(event.target.value);
  };
  const handleAddProject = () => {
    const userData = { ...user } as IUser;
    const projectSelected = projects?.find((object) => {
      return object.id === project;
    });
    if (projectSelected) {
      const newProject: IProject = { id: projectSelected.id, name: projectSelected.name };
      userData.projects = [...(userData.projects ?? []), newProject];
      setUser(userData);
      setProject(undefined);
      setProjects(projects?.filter(project => projectSelected.id !== project.id));
    }
  };
  const handleDeleteProject = (indexToDelete: number) => {
    const userData = { ...user } as IUser;
    const newProject:IProjectUserData = { id:userData.projects[indexToDelete].id, name: userData.projects[indexToDelete].name } as IProjectUserData;
    setProjects((oldProjects:any) => [...oldProjects, newProject].sort((a, b) => {
      if (a.id < b.id) { return -1; }
      if (a.id > b.id) { return 1; }
      return 0;
    }));
    userData.projects = userData.projects?.filter((project, index) => index != indexToDelete);
    setUser(User => ({ ...User, ...userData }));
  };
  const fetchProjects = async () => {
    await ProjectsApiWrapper.getProjects()
      .then((projects) => {
        if(userData.projects && userData.projects.length > 0)
          setProjects(projects.filter(project => {
            return !userData.projects.find((userProject: any) => {
              return userProject.id === project.id;
            });
          }));
        else
          setProjects(projects);
      });
  };
  const fetchUser = async () => {
    await MicrosoftGraphApiWrapper.getProfile()
      .then((profile) => {
        setAzAdProfile(profile);
        setUser({
          name: profile.givenName,
          surname: profile.surname,
          email: profile.mail,
          projects: userData?.projects,
          obId: profile.id
        } as IUser)
      })
      .catch(function (e) {
        console.log(e);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    setloading(true);
    fetchProjects();
    fetchUser();
  }, []);

  useEffect(() => {
    if (!userData) { navigate("/") }
  }, [userData]);


  if (loading) return <Loading />;

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Avatar className={classes.avatar}><ImageComponent /></Avatar>
          <Breadcrumbs separator={<NavigateNext fontSize="large" color="disabled" />} aria-label="breadcrumb">
            <Typography variant="h5" className={classes.typographyGreen}>{azAdProfile?.displayName}</Typography>
            <Typography variant="h5" className={classes.typography}>Update your profile</Typography>
          </Breadcrumbs>
          <Grid className={classes.subtitle} container direction="row" alignItems="center">
            <Grid item>
              <Avatar className={classes.subtitleIcon}>
                <AccountCircle />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography className={classes.typography} component="div" variant="h5">Contact informations</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
            <Grid item md={8}>
              <TextField
                fullWidth
                inputProps={{ className: classes.input }}
                placeholder="Name"
                name="lmsUserNameInput"
                id="lmsUserNameInput"
                label="Names"
                variant="standard"
                margin="dense"
                value={user?.name}
                onChange={handleChangeName}
                disabled={true} />
              <TextField
                fullWidth
                inputProps={{ className: classes.input }}
                placeholder="Surname"
                name="lmsUserSurnameInput"
                id="lmsUserSurnameInput"
                label="Surname"
                variant="standard"
                margin="dense"
                value={user?.surname}
                onChange={handleChangeSurname}
                disabled={true} />
              <TextField
                fullWidth
                inputProps={{ className: classes.input }}
                placeholder="Email"
                name="lmsUserEmailInput"
                id="lmsUserEmailInput"
                label="Email"
                variant="standard"
                margin="dense"
                value={user?.email}
                onChange={handleChangeEmail}
                disabled={true} />
            </Grid>
            <Grid item md={4}>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar className={classes.avatarSmall}>
                    <PinDrop color="primary" />
                  </Avatar>
                </Grid>
                <br /><br />
                <Grid item>
                  <Typography noWrap>{azAdProfile?.officeLocation}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2} className={classes.marginTop}>
                <Grid item>
                  <Avatar className={classes.avatarSmall}>
                    <AssignmentInd color="primary" />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography noWrap>{azAdProfile?.jobTitle}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.subtitle} container direction="row" alignItems="center">
            <Grid item>
              <Avatar className={classes.subtitleIcon}>
                <LibraryBooks />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography className={classes.typography} component="div" variant="h5">Projects</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item md={9}>
              <FormControl fullWidth variant="standard">
                <Select value={project ?? ""} className={classes.input} onChange={handleChangeProjectName}>
                  {
                    projects?.map((project) => (
                      <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <Button onClick={handleAddProject} variant="contained" size="small" startIcon={<Add />}>Add Project</Button>
            </Grid>
          </Grid>
          <FormHelperText id="lmsProjectsInput-text">Here are some of the projects I've worked on</FormHelperText>
          <List>
            {user?.projects?.map((project, index) => (
              <ListItem key={index} button>
                <ListItemText primary={project.name} />
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteProject(index)} >
                  <ClearSharp />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button type="submit" variant="contained" color="secondary" className={classes.button} id="lmsProfileSaveButton" size="large" disabled={loadingButton}>Save</Button>
          {loadingButton && (
            <CircularProgress className={classes.circularProgress} />
          )}
        </CardActions>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle}><CheckCircleOutlined className={classes.checkCircleOutlined} /></DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentText}>Your account has been saved successfully!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Continue</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProfilePage;


