import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './styles';
import InputNumber from 'components/inputNumber';
import FloorEditor from './components/FloorEditor';
import colors from 'utils/colors';
import { IRootReducer } from 'types/AppInterfaces';
import { useDispatch, useSelector } from 'react-redux';
import DrawMenuContainer from '../../containers/dev/draw-menu';
import { Controller, useForm } from 'react-hook-form';
import { areaByFigureValues, floorFieldValues, floorFieldValuesForm } from './form/floorFields';
import { SaveFloorModal } from './saveFloorModal';
import FloorsApiWrapper from 'utils/apiWrappers/floorsApiWrapper';
import { showTabs } from 'redux/home/home.action';
import { IFigures } from 'types/shared';
import FiguresApiWrapper from 'utils/apiWrappers/figuresApiWrapper';
import { selectFloor } from 'redux/floors/actions';
import TextField from 'components/form/textFloor';
import TextareaField from 'components/form/textareaFloor';
import Select from 'components/form/selectFloor';
import AreaByFiguresApiWrapper from 'utils/apiWrappers/areaByFiguresApiWrapper';

type Coordinate = {
	x: number;
	y: number;
};
interface FloorScreenProps {
 fetchFloors: () => void;
}

const FloorScreen: React.FC<FloorScreenProps> = ({fetchFloors}) => {
	const activeBuilding = useSelector((state: IRootReducer) => state.buildings.active);
	const activeFloor = useSelector((state: IRootReducer) => state.floors.selected);
	const [areaType, setAreaType] = useState(1);

	const [figures, setFigures] = useState<IFigures[]>([]);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [positionX, setPositionX] = useState(0);
	const [positionY, setPositionY] = useState(0);
	const [floorName, setFloorName] = useState('');
	const [floorDescription, setFloorDescription] = useState(activeFloor?.description);
	const [formDataFloor, setFormDataFloor] = React.useState<floorFieldValues | undefined>();
	const [formDataAreaByFigure, SetFormDataAreaByFigure] = React.useState<areaByFigureValues | undefined>();
	const [isOpen, setIsOpen] = React.useState(false);
	const dispatch = useDispatch();
	const { control } = useForm<floorFieldValuesForm, object>();

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const classes = useStyles();

	const [initialPoint, setInitialPoint] = useState<Coordinate>({ x: 0, y: 0 });
	const [mouseDown, setMouseDown] = useState(false);
	const isNew = activeFloor === undefined;

	function onFiguresClick() {
		console.log('Floor figures click');
	}

	function onSaveClick() {
		console.log('Floor save click');
		onOpenModal();
	}

	function onEditClick() {
		console.log('Floor edit click');
	}

	function onDeleteClick() {
		console.log('Floor delete click');
	}

	const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
		if (!canvasRef.current) {
			return;
		}
		const canvas: HTMLCanvasElement = canvasRef.current;
		return {
			x: event.pageX - canvas.offsetLeft,
			y: event.pageY - canvas.offsetTop
		};
	};

	useEffect(() => {
		if (activeFloor === undefined) {
			setPositionY(0);
			setPositionX(0);
			setFloorName('');
			setFloorDescription('');
			setHeight(0);
			setWidth(0);
			setAreaType(1);
		}else{
			setHeight(activeFloor.areaByFigure?.height || height);
			setWidth(activeFloor.areaByFigure?.width || width);
			setAreaType(activeFloor.areaByFigure?.figureId || areaType);
			setPositionY(activeFloor.y);
			setPositionX(activeFloor.x);
			setFloorName(activeFloor.name);
			activeFloor.description === null ? setFloorDescription('') : setFloorDescription(activeFloor.description);
		}
		fetchFigures();
	}, [activeFloor, isNew]);

	const fetchFigures = async () => {
		const figures = await FiguresApiWrapper.getFigures();
		setFigures(figures);
	};

	const handleMouseDown = useCallback(
		(event: MouseEvent) => {
			const coordinates = getCoordinates(event);
			if (coordinates) {
				setMouseDown(true);
				setInitialPoint(coordinates);
				setPositionX(coordinates.x);
				setPositionY(coordinates.y);
			}
		},
		[getCoordinates]
	);

	useEffect(() => {
		if (!canvasRef.current) {
			return;
		}
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousedown', handleMouseDown);
		return () => {
			canvas.removeEventListener('mousedown', handleMouseDown);
		};
	}, [handleMouseDown]);

	const handleMouseUp = useCallback(() => {
		setMouseDown(false);
	}, []);

	useEffect(() => {
		if (!canvasRef.current) {
			return;
		}
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mouseup', handleMouseUp);
		canvas.addEventListener('mouseleave', handleMouseUp);
		return () => {
			canvas.removeEventListener('mouseup', handleMouseUp);
			canvas.removeEventListener('mouseleave', handleMouseUp);
		};
	}, [handleMouseUp]);

	const paint = (initialPoint: Coordinate, finalPoint: Coordinate) => {
		if (!canvasRef.current) {
			return;
		}
		const canvas: HTMLCanvasElement = canvasRef.current;
		const context = canvas.getContext('2d');
		if (context) {
			context.clearRect(0, 0, canvas.width, canvas.height); //clear canvas

			context.beginPath();
			let width = finalPoint.x - initialPoint.x;
			let height = finalPoint.y - initialPoint.y;
			setWidth(width);
			setHeight(height);

			context.fillRect(initialPoint.x, initialPoint.y, width, height);
			context.fillStyle = colors.apexOrange2;
			context.lineWidth = 1;
			context.closePath();
			context.stroke();
		}
	};

	const handleMouseMove = useCallback(
		(event: MouseEvent) => {
			if (!canvasRef.current) {
				return;
			}
			if (mouseDown) {
				const finalPoint = getCoordinates(event);
				if (initialPoint && finalPoint) {
					paint(initialPoint, finalPoint);
				}
			}
		},
		[mouseDown, initialPoint]
	);

	useEffect(() => {
		if (!canvasRef.current) {
			return;
		}
		const canvas: HTMLCanvasElement = canvasRef.current;
		canvas.addEventListener('mousemove', handleMouseMove);
		return () => {
			canvas.removeEventListener('mousemove', handleMouseMove);
		};
	}, [handleMouseMove]);

	const onOpenModal = () => {

		const dataAreabyFigure ={
			id: activeFloor && activeFloor?.areaByFigure.id,
				figureId: areaType,
				figureName: '',
				description: '',
				height:height,
				width:width,
				radius: 0,
				points: ''
		}
		SetFormDataAreaByFigure(dataAreabyFigure);

		const dataFloor = {
			id: activeFloor && activeFloor?.id,		
			name:floorName,
			description:floorDescription,
		    x:positionX,
	        y:positionY,
			buildingId: activeBuilding?.id,
			buildingName: activeBuilding?.name,
			areaByFigure: dataAreabyFigure
		}

		setFormDataFloor(dataFloor);
		setIsOpen(true);

	};
	const onCloseModal = () => {
		setIsOpen(false);
	};

	const onSubmit = async () => {
		const Request = async (formDataFloor: floorFieldValues, formDataAreaByFigure: areaByFigureValues) => {
			if(isNew){
				const areaBF = await AreaByFiguresApiWrapper.postAreaByFigure(formDataAreaByFigure)
				if(areaBF){
					const setAreaByFigureId = {...formDataFloor}
					setAreaByFigureId.areaByFigure.id = areaBF.id;
					setFormDataFloor(setAreaByFigureId)

					const active = await FloorsApiWrapper.postFloor(formDataFloor);
					const Newid = active.id;
					if (active){
						fetchFloors();
						const active = await FloorsApiWrapper.getFloor(Newid!);
						dispatch(selectFloor(active));
					} 
				}
			}else{
				const resp = await FloorsApiWrapper.putFloor(formDataFloor);
				if (resp){
					fetchFloors();
					const id = formDataFloor.id;
					const active = await FloorsApiWrapper.getFloor(id!);
					dispatch(selectFloor(active));
				} 
			}
		}
		if (formDataFloor != undefined && formDataAreaByFigure != undefined) Request(formDataFloor!, formDataAreaByFigure!);
	};

	const cancelData = () => {
		dispatch(showTabs(false));
	};

	return (
		<div>
		<SaveFloorModal floorName = {floorName} isOpen={isOpen} onClose={onCloseModal} onSubmit={onSubmit} />
		  <form>
			<Box className={classes.optionsBox}>
			<Box px={1} pb={2} textAlign={'start'}>
			<Controller
			name={'name'}
			control={control}
			render={({ field: { value=floorName } }) => (
				<TextField value={value} onChange={(e)=>{setFloorName(e.target.value)}} label={'Name'} required={true} />
		    )}
		    />
			</Box>
			<Box px={1} pb={2} textAlign={'start'}>
				<Controller
					name={'areaType'}
					control={control}
					render={({ field: {value=areaType} }) => (
						<Select
						onChange={(e) => {setAreaType(e.target.value as number)}}
						label={'AreaType'}
						items={figures}
						value={value}
						required={true}
						name={'areaType'}
						/>
					)}
				/>
			</Box>
            <Controller
				name={'height'}
				control={control}
				render={({ field: { value=height } }) => (	
					<InputNumber
						label={"Height"}
						placeholder="0"
						value={value}
						handleChange={setHeight}
						required={true}
					/>
				)}
			/>

             <Controller
				name={'width'}
				control={control}
				render={({ field: { value=width } }) => (	
					<InputNumber
						label="Width"
						placeholder="0"
						value={value}
						handleChange={setWidth}
						required={true}
					/>
				)}
			/>
			 <Controller
				name={'x'}
				control={control}
				render={({ field: { value=positionX } }) => (	
					<InputNumber
						label="Position X"
						placeholder="0"
						value={value}
						handleChange={setPositionX}
						required={true}
					/>
				)}
			/>
            <Controller
				name={'y'}
				control={control}
				render={({ field: { value=positionY } }) => (	
					<InputNumber
						label="Position Y"
						placeholder="0"
						value={value}
						handleChange={setPositionY}
						required={true}
					/>
				)}
				/>
				<Box px={2} pb={1} textAlign={'start'}>
			    <Controller
				name={'description'}
				control={control}
				render={({ field: { value=floorDescription } }) => (
					<TextareaField value={value} onChange={(e)=>{setFloorDescription(e.target.value)}} label={'Description'} required={true} />
				)}
				/>
			    </Box>
			
			</Box>
			<FloorEditor canvasRef={canvasRef} />
			<DrawMenuContainer
				onFiguresClick={onFiguresClick}
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
				onSaveClick={onSaveClick}
			/>
			</form>
		</div>

	);
};

export default FloorScreen;
