import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "@material-ui/core/Button";
import useStyles from "./SignOutButton.styles";

const SignOutButton = () => {
    const classes = useStyles();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    return (
        <Button className={classes.logOutButton} onClick={handleLogout}>Logout</Button>
    );
}

export default SignOutButton;