import axios from 'axios';
import { IFigures, IFloor } from 'types/shared';
import { apiURL, QueryParams } from 'utils/constants';

const figuresURL = apiURL + 'Figures';

const FiguresApiWrapper = {
	getFigures: (params?: QueryParams): Promise<IFigures[]> => {
		const figures = axios
			.get(figuresURL, { params })
			.then((result) => {
				const figuressList = result.data as IFigures[];
				return figuressList.map((figure) => {
					return { ...figure, description: figure.description !== null ? figure.description : ''} 
				})	
				} 
			)
		return figures;
	},

};

export default FiguresApiWrapper;
