import React, { useEffect, useState } from 'react';

import { Route, Routes, useNavigate } from 'react-router-dom';
import Container from './HOC/container/container.hoc';
import HomePage from './pages/homePage/homePage';
import Header from './components/header/header.component';

//containers
import ZonesContainer from './containers/zones/index';
import ZoneContainer from './containers/zones/individual';
import FormContainer from './containers/dev/form';
import DrawMenuContainer from './containers/dev/draw-menu';
import GroupsContainer from './containers/groups';
import WorkstationContainer from './containers/workstations';

import './App.scss';
import Theme from './utils/theme';
import BuildingOptions from './components/buildings-options/buildings-options';
import AssignSeatPage from 'pages/assignSeatPage';
import ProfilePage from './pages/profilePage';
import ZoneEditor from 'components/zoneEditor';
import TabsHome from './components/tabsHome';
import SingleGroup from 'containers/singleGroup';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated  } from "@azure/msal-react";
import SignInButton from "./components/button/SignIn/SignInButton";
import EmployeesApiWrapper from 'utils/apiWrappers/employeesApiWrapper';

function App() {

	const { accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();
	const [userId, setUserId] = useState(true);
	const [userData, setUserData] = useState(undefined);
	

	useEffect(() => {
		if(isAuthenticated)
			fetchUserData(accounts[0] && accounts[0].username);
	}, []);

	const fetchUserData = (email:any) => {
		EmployeesApiWrapper.getEmployeeAuthData(email)
			.then((result: any) => {
				if(!result.guid) {
					setUserData(result);
					setUserId(false);
					navigate('/profile', { state: {propUserData: result} })
				} else {
					setUserData(result);
					setUserId(true);
				}
			}).catch((error) => {
				setUserId(false);
				navigate('/profile',{ state: {propUserData: {} }});
			})
	};

	return (
		<Theme>
			<div className="App" data-test="appComponent">
				{userId && <Header />}
				<AuthenticatedTemplate >
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/assign-seats" element={<AssignSeatPage />} />
						<Route path="/zones" element={<ZonesContainer />} />
						<Route path="/zones/:zoneId" element={<ZoneContainer match={undefined} />} />
						<Route path="/dev/form-elements" element={<FormContainer />} />
						<Route
							path="/dev/draw-menu"
							element={
								<DrawMenuContainer
									onFiguresClick={function (): void {
										throw new Error('Function not implemented.');
									}}
									onEditClick={function (): void {
										throw new Error('Function not implemented.');
									}}
									onDeleteClick={function (): void {
										throw new Error('Function not implemented.');
									}}
									onSaveClick={function (): void {
										throw new Error('Function not implemented.');
									}}
								/>
							}
						/>
						<Route path="/dev/building" element={<BuildingOptions />} />
						<Route path="/groups" element={<GroupsContainer />} />
						<Route path="/profile" element={<ProfilePage propUserData={userData} handleClickSave={() => fetchUserData(accounts[0] && accounts[0].username) }/>} />
						<Route path="/groups/create" element={<SingleGroup />} />
						<Route path="/groups/edit/:groupId" element={<SingleGroup />} />
						<Route path="/workstation" element={<WorkstationContainer />} />
					</Routes>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<SignInButton handleAuthenticated={(email) => fetchUserData(email)} />
				</UnauthenticatedTemplate>
			</div>
		</Theme>
	);
}

export default App;
