import { makeStyles, Theme } from '@material-ui/core/styles';
import Colors from 'utils/colors';
export interface StyleProps {
  height: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  optionsBox: {
    backgroundColor: Colors.apexBlueLighter,
    //border: `3px solid ${Colors.apexOrange2}`,
    //borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    margin: '0px 0 5px 0',
    padding: '5px 15px',
    textAlign: 'left',
    justifyContent: 'space-between',

    '& textarea, input': {
      color: Colors.apexBlue,
    },
    '& label': {
      minWidth: 60,
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
    },
    '& input.MuiInputBase-input.MuiInput-input': {
      padding: '2px',
      fontWeight: 'bolder',
    },
    '& textarea': {
      minWidth: '250px',
      fontSize: '16px',
    },
    '& input[type=tel]': {
      maxWidth: '50px',
    },
  },
  canvasContainer: {
    width: '100%',
    height: ({height}) => height,
    display: 'flex',
    flexDirection: 'row',
    color: Colors.apexBlue,
    fontFamily: "Libre Franklin",
    zIndex: 2,

    '& .yAxisLabels': {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'bolder',
      justifyContent: 'space-between',
      height: '107%',
      paddingRight: '5px',
      marginLeft: '-25px',
    },

    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',

      '& .xAxisLabels': {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bolder',
        justifyContent: 'space-between',
      },
      '& .spaces': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,

        '& .square': {
          width: '15px',
          height: '15px',
          border: `1px solid ${Colors.apexGrey3}`,
          borderRadius: 5,
          marginRight: 3,
          marginLeft: '1%',
        },

        '& .orange': {
          backgroundColor: Colors.apexOrange2,
        },
      },
      '& .dottedSpacedLeft':{
        backgroundImage: `linear-gradient(to bottom, ${Colors.apexGrey3} 70%, rgba(255, 255, 255, 0) 0%)`,
        backgroundPosition: 'left',
        backgroundSize: '1px 25px',
        backgroundRepeat: 'repeat-y',
        height: '105%',
        width: '10px',
        marginTop: '-30px',        
        zIndex: 1
      },
      '& .dottedSpacedTop':{
        backgroundImage: `linear-gradient(to right, ${Colors.apexGrey3} 70%, rgba(255, 255, 255, 0) 0%)`,
        backgroundPosition: 'top',
        backgroundSize: '25px 1px',
        backgroundRepeat: 'repeat-x',
        width: '100%',        
      },
      '& .dashedAxis': {
        padding: 4,
        display: 'flex',
        alignItems: 'end',        
        margin: '-4px 0 2px 10px',
        height: 'inherit',

        '& .canvas-container': {
          width: '90%',
          height: '98%',
        },

        '& canvas': {
          border: `1px solid ${Colors.apexGrey3}`,
          width: '90%',
          height: '98%',
          borderRadius: 5,
        },
      },
    },
  },
}));

export default useStyles;
