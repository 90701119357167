enum WorkStationsActionTypes {
    GET_WORKSTATIONS_COLLECTION = 'GET_WORKSTATIONS_COLLECTION',
    SET_NAME_QUERY = 'SET_NAME_QUERY',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_SORT = 'SET_SORT',
    SET_ERROR = 'SET_ERROR',
    SET_LOADING = 'SET_LOADING',
    SET_WORKSTATION_COLLECTION = 'SET_WORK_STATION_COLLECTION',
    SET_SELECTED_WORKSTATION = 'SET_SELECTED_WORKSTATION',
    SAVE_WORKSTATION = 'SAVE_WORK_STATION',
    GET_WORKSTATIONS_OPTION = 'GET_WORKSTATIONS_OPTION'
}

export default WorkStationsActionTypes;